@import "~ng-pick-datetime/assets/style/picker.min.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;600;700;800&display=swap");

body {
  font-family: "Open Sans", sans-serif !important;
  background-color: #fbfbfb;
  /*--font-family: 'Cairo', sans-serif;*/
}
* {
  box-sizing: border-box;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
  margin: 0;
  padding: 0;
}
button:focus {
  outline: none !important;
}

.mat-h2,
.mat-title,
.mat-typography h2 {
  margin: 0 !important;
}
a {
  text-decoration: none;
}
li,
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

.img_show img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

i.fas.fa-ellipsis-v {
  color: #adadad;
}
.conta_iner {
  width: 90%;
  margin: 0 auto;
}

html {
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
    padding: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background:#00649e;
    border-radius: 10px;
  }
}
/*----altrnative css------*/
.flex-one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  animation-direction: normal;
}
.all-sp {
  padding: 20px 0px;
}
.mat-typography h3 {
  margin: 0;
}
/*----scss-header---*/
.nav-link {
  font-size: 14px;
  font-weight: bold;
  color: #000000 !important;
  line-height: 30px;
  button {
    padding: 7px 11px;
    border-radius: 7px;
    border: 2px solid #00649e !important;
    color: #00649e;
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
    &:first-child {
      font-size: 13px;
      font-weight: 500;
      color: #00649e;
    }
  }
}
li.nav_links a {
  border: 1px solid #f0f0f0;
  border-radius: 25px;
  padding: 6px 15px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(10px, 5px);
  &:first-child {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
// ul.navbar-nav.ml-auto {
//   width: 100%;
// }

.navbar-nav  .mat-select#mat-select-0 {
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 5px;
    width: 110px;
}
span.mat-select-min-line.ng-tns-c3-0.ng-star-inserted {
  font-size: 12px;
  font-weight: 500;
  color: #00649e;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0px

}
.navbar-brand figure{
  width: 141px;
}
ul.navbar-nav.ml-auto .mat-select-trigger {
  border: 2px solid #00649e;
  border-radius: 7px;
  padding: 4px 4px;
}
figure.xport {
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.xport button.mat-focus-indicator.mat-tooltip-trigger.mat-raised-button.mat-button-base {
  background: none;
  box-shadow: none;
}
figure.xport img {
  width: 16px !important;
  height: 16px!important;
  border-radius: unset;
}




/*----- Subscription scss----*/

.flx-lft {
  h2 {
    font-size: 22px;
    font-weight: bold;
    line-height: normal;
  }
}
.flx-rght {
  button {
    padding: 10px 15px;
    border-radius: 7px;
    border: 1px solid #00649e;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    color: #ffffff;
    background: #00649e;
    cursor: pointer;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.flx-rgt button {
  padding: 10px 15px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  color: #ffffff;
  background: #00649e;
  width: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flx-rgt {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.innr-flx {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 32%;
  position: relative;
  margin-bottom: 22px;
  padding: 10px;
  border-radius: 7px;
  margin-right: 22px;
  box-shadow: 0px 0px 1px 2px #0000000d;
  h3 {
    font-size: 22px;
    font-weight: bold;
    line-height: 50px;
    color: #202020;
  }
  h4 {
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    color: #00649e;
    span {
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      color: #a5a5a5;
    }
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    color: #000000;
    display: block;
    .s-blk {
      display: block;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      color: #a5a5a5;
    }
  }
}
.innr-flx:nth-child(3) {
  margin-right: 0;
}
.innr-flx:nth-child(6) {
  margin-right: 0;
}

.rght-txt {
  display: flex;
  align-items: center;
  justify-content: space-between;

  ui-switch {
    margin-right: 14px;
    margin-top: 7px;
  }
}
.flex-two {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.offr_outlet {
  .offr {
    background: url(assets/images/bg.png);
    width: 100px;
    height: 80px;
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 100px;
    position: absolute;
    bottom: 40px;
    right: 0;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 500;
  }
}
.ribbon {
  background: url(assets/images/bg.png);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 100px;
  position: absolute;
  bottom: 15px;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.eys {
  position: absolute;
  color: #00649e;
  top: 24px;
  right: 75px;
}

/*-------paln-detail-----*/
.pln-dtl {
  .flx-lft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.enbl-offr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-select {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #6e707e;
  border: 1px solid #e5ecf3;
  border-radius: 10px;
  padding: 8px 0;
}
label {
  font-size: 16px;
  color: #c7c7c7;
  font-weight: 500;
  line-height: 22px;
}
.dlt-typ h6 {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  padding: 10px 0px;
}
.dtl_typ {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;
}
.form-control{
  font-size: 14px!important;
}
.dtl_typz .mat-checkbox {
  width: 20%;
}
.enbl-offr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 31%;
  margin-bottom: 10px;
  margin-left: 25px;
  h3 {
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    color: #c7c7c7;
  }
  .switch.switch-medium {
    height: 25px;
    border-radius: 30px;
    min-width: 58px;
    background: #fff;
    small {
      background: #46b700 !important;
      width: 25px;
      height: 25px;
    }
  }
}
.pln-btn {
  button {
    padding: 10px 25px;
    background: #00649e;
    border: 1px solid #00649e;
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }
}
.cmn_frm .input-group input,
.cmn_frm .input-group mat-select,
.cmn_frm .input-group textarea.form-control {
  background: #c3c3c32e !important;
  border: 1px solid #f5f5f5 !important;
  border-radius: 14px !important;
}

.nw_field input,
.nw_field select,
.nw_field .multiselect-dropdown {
  background: whitesmoke;
  border-radius: 14px !important;
  border: 1px solid #f5f5f5 !important;
}

/*Rocky css start*/
.modules_wrpper {
  margin: 30px 0px;
  .flex_wrp {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .innr_module {
      width: 32%;
      border-radius: 6px;
      padding: 10px;
      position: relative;
      box-shadow: 0px 0px 3px 2px #00000008;

      h3 {
        font-size: 20px;
        color: #000;
        font-weight: 700;
        span {
          color: #a5a5a5;
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
        }
      }

      span {
        display: block;
      }
      .btm_dta {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 12px 0px;
        gap: 10px;
        .schedule {
        width: 55%;
          h6 {
            color: #c7c7c7;
            font-size: 16px;
            line-height: normal;
            font-weight: 600;
            span {
              color: #000;
              font-weight: 500;
              font-size: 14px;
              white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 190px;
            }
          }
        }
        figure {
          width: 35%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .edit {
        position: absolute;
        top: 10px;
        right: 20px;
        li {
          width: 25px;
          height: 25px;
          background: #fff;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 0px 2px 2px #00000012;
          margin-bottom: 10px;
          cursor: pointer;
          i.fa.fa-eye,
          i.fa.fa-solid.fa-trash,
          i.fas.fa-pencil-alt {
            color: #00649e;
          }
        }
      }
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
  }
  .flx-lft {
    display: flex;
    align-items: center;
    i.fa.fa-arrow-left {
      font-size: 20px;
      margin-right: 15px;
    }
  }
  .flx-rght {
    display: flex;
    align-items: center;
    width: 30%;
    i.fas.fa-ellipsis-v {
      margin-left: 12px;
      font-size: 20px;
      color: #a3a3a3;
    }
  }
}
/*Rocky css end*/
.bigholderhor {
  height: 265px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  border: 1px solid #ededed;
  width: 100%;
}

.image_placeholder_showup_file {
  top: 0;
  z-index: 0;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dftlp {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dftlp img {
  width: 50px;
}
.image_placeholder_showup_file input {
  opacity: 0;
  width: 100%;
  height: 100%;
}
.frm-flx {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0px;
  .lft-frm {
    width: 75%;
  }
  .rght-frm {
    width: 24%;
  }
}
/*-----client-list------*/
.clnt-lst {
  .flx-lft {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 10%;
  }
  .flx-rght {
    display: flex;
    align-items: center;
    button {
      margin-right: 7px;
    }
    .fas {
      color: #a3a3a3;
    }
  }
  table {
    width: 100%;
    td,
    th {
      width: 138px;
    text-align: center;
    padding: 15px 6px;
    font-size: 13px;
    }
  }
  th {
    .mat-checkbox-lable {
      font-size: 18px;
      font-weight: bold;
      color: #000000;
    }
  }
  td {
    .mat-checkbox-lable {
      span {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
      }
    }
    button {
      background: #d8ffe0;
      border: unset;
      padding: 7px 8px;
      border-radius: 6px;
      color: #38e25d;
      font-size: 12px;
      font-weight: 500;
      line-height: unset;
      width: 100px;
      display: block;
    margin: 0 auto;
    }
    .fas {
      color: #a3a3a3;
    }
  }
  .clr {
    color: #00649e;
  }
}
.invlid-feedback {
  color: #ff00008f;
}
.table-bordered td, .table-bordered th {

  font-size: 13px;
  vertical-align: middle;
  border: none;
}
.table-bordered {
  border: none !important;
}
.table-bordered thead td, .table-bordered thead th {
  border: none;
}
.btn-info {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 30px;
  margin:0 auto;
}

.offrc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 0px;
}
button.btn.btn-primary.ftre {
  width: 38px;
  padding: 12px;
}

.extension_wrp {
  .flx-lft i.fa.fa-arrow-left {
    font-size: 20px;
    margin-right: 15px;
  }
  .innr_module {
    width: 32%;
    border-radius: 6px;
    padding: 10px;
    position: relative;
    box-shadow: 0px 0px 3px 2px #00000008;


    h4 {
      color: #000;
      font-size: 18px;
      font-weight: 400;
      width: 240px;
      line-height: normal;

      span {
        display: block;
        color: #a5a5a5;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        margin-top: 5px;
      }
    }
    figure {
      width: 65px;
      height: 65px;
      border-radius: 100px;
      border: 1px solid #dedede;
      overflow: hidden;
    }
    &:nth-child(3n + 3) {
      margin-right: 0;
    }
  }
  .btn_end {
    position: absolute;
    right: 20px;
    bottom: 7px;
    button {
      background: #d8ffe0;
    color: #38e25d;
    border: unset;
    padding: 1px 9px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
      i.fa.fa-check {
        margin-right: 5px;
      }
    }
  }
  .btn_top {
    width: 35px;
    height: 35px;
    background: #fff;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 2px 2px #00000012;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    i.fas.fa-pencil-alt {
      font-size: 14px;
    }
    i.fa-solid.fa-trash {
      color: #00649e;
    }
  }
  .flex_wrp {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;
  }
  .flex-one {
    padding-right: 20px;
    margin: 5px 0px;
    h2 {
      font-size: 20px;
      font-weight: 700;
      color: #000;
      line-height: normal;
    }
    p {
      color: #00649e;
      font-size: 22px;
      font-weight: 600;
      margin: 0;
    }
  }
}
.offr p{
  color: #fff;
}
.rght-txt .mat-slide-toggle {
  margin-right: 12px;

}
.fd_flx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.outrwrap {
  background: #fff;

  padding: 10px;
  border-radius: 10px;
}

.innr-flx:nth-child(3n + 3) {
  margin-right: 0;
}
/*------dashboard---*/
.drpdwn {
  display: flex;
    align-items: center;
    justify-content: space-between;
    width: 20%;
    border: 1px solid #80808026;
    height: 30px;
    border-radius: 5px;
  .mat-select-arrow {
    color: #6418c3;
  }
  .mat-label {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }
}
.mat-form-field-underline {
  display: none;
}
.dashboard {
  .flex-one {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    .flx-rght {
      width: 100%;
      .flex-one {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        margin: 0px 10px 20px 0px;
      }
      .innr-one {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 24%;
        box-shadow: 1px 0px 2px 2px #0000000a;
        border-radius: 7px;
        padding: 10px;

        figure {
          width: 80px;
        }
        h2 {
          font-size: 22px;
          font-weight: bold;
          line-height: 50px;
          .grn {
            color: #38e25d;
            display: inline-block;
            padding-right: 5px;
          }
          span {
            display: block;
            font-size: 18px;
            font-weight: 400;
            line-height: normal;
            color: #a5a5a5;
          }
          .d-blk {
            display: block;
            color: #202020;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
          }
        }
      }
    }
    .prjct-stcs {
      width: 49%;
      box-shadow: 0px 0px 2px 2px #0000000d;
      padding: 10px;
      border-radius: 6px;
      .mat-select {
        display: inline-block;
        width: 100%;
        outline: none;
      }
      h2 {
        font-size: 20px;
        font-weight: bold;
        line-height: normal;
      }

      .innr-frst {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 35%;
        h2 {
          font-size: 20px;
          font-weight: bold;
          line-height: normal;
          span {
            display: block;
            font-size: 16px;
            font-weight: 400;
            line-height: normal;
            color: #202020;
          }
        }
      }
      .innr-two {
        width: 48%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .innr {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          width: 50%;
          h2 {
            font-size: 20px;
            font-weight: bold;
            line-height: 30px;
            span {
              display: block;
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
      }
      .lwr-one {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        .flx-prpl {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 15px;
          h3 {
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            margin-right: 10px;
          }
          .switch.switch-medium {
            height: 25px;
            border-radius: 30px;
            small {
              width: 25px;
              height: 25px;
              background: #6418c3 !important;
            }
          }
          .gray {
            .switch.switch-medium {
              height: 25px;
              border-radius: 30px;
              small {
                width: 25px;
                height: 25px;
                background: #a5a5a5 !important;
              }
            }
          }
        }
      }
    }
    .prjct-rt {
      width: 49%;
      box-shadow: 0px 0px 2px 2px #0000000d;
      padding: 10px;
      border-radius: 6px;

      .flex-one {
        h2 {
          font-size: 20px;
          font-weight: bold;
          line-height: normal;
        }
        .innr-up {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          width: 43%;
          figure {
            margin-left: 10px;
          }
          h2 {
            font-size: 20px;
            font-weight: bold;
            line-height: 35px;
          }
          span {
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            color: #a5a5a5;
          }
          .d-blk {
            color: #202020;
            display: block;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
          }
          .grn {
            color: #38e25d;
          }
        }
      }
    }
    .flx-left {
      width: 22%;
      h2 {
        font-size: 22px;
        font-weight: bold;
        line-height: normal;
      }
      p {
        color: #a5a5a5;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        margin: 8px 0px;
      }

      .flx-lg {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 4.1px 0px;
        figure {
          margin-right: 10px;
        }

        p {
          color: #202020;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin: 0px;
        }
        h2 {
          font-size: 16px;
          font-weight: bold;
          line-height: normal;
        }
      }
    }
  }
}







.btn.btn-primary {
  position: relative;
  z-index: 1;
  border: unset;
  &:before {
    content: "";
    top: 0;
    transform: translateX(100%);
    width: 45%;
    position: absolute;
    bottom: 0;
    z-index: -1;
    animation: slide 2s infinite;
    background: linear-gradient(
      to right,
      rgba(64, 235, 241, 0) 0%,
      rgb(255 254 253/56%) 50%,
      rgb(255 254 253/28%) 99%,
      rgba(64, 235, 241, 0) 100%
    );
  }
  @keyframes slide {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}

/*rocky dashboard css strt*/
.dashboard .flex-one .flx-left {
  width: 27%;
  box-shadow: 1px 0px 2px 2px #0000000a;
  padding: 10px;
  border-radius: 7px;
}

.top_img {
  width: 160px;
  margin: 0 auto;
}

/*rocky dashboard css end*/
.clnt-lst .flx-rght {
  display: flex;
  align-items: center;
}
.todo-btn {
  width: 11% !important;
}
.edit li i.fa.fa-solid.fa-trash,
i.fas.fa-pencil-alt,
i.fa.fa-eye {
  font-size: 14px;
}
.btm_first_one {
  margin-bottom: 0px !important ;
}
.edt-list .flx-lft.fd_flx :last-child {
  margin-left: 10px;
}
/*---swati css----*/
.extension_wrp {
  .flex-one {
    button {
      padding: 10px 15px;
      border-radius: 7px;
      border: 1px solid #00649e;
      font-size: 14px;
      font-weight: bold;
      line-height: normal;
      color: #ffffff;
      background: #00649e;
      cursor: pointer;
      width: 135px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
/*-----add-extnsn-----*/
.add-extnsn .flex-one h2 {
  font-size: 34px;
  font-weight: bold;
  line-height: normal;
}
.add-extnsn .form-control {
  font-size: 20px;
  font-weight: 400;
  color: #202020;
}
.mddl-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mddl-btn button {
  padding: 10px 13px;
  border-radius: 10px;
  border: 1px solid #00649e;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  color: #ffffff;
  background: #00649e;
  cursor: pointer;
  width: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*----edt-list-----*/
.edt-list .flex-one .flx-lft {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 15%;
}
.edt-list .flex-one .flx-rght {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 13%;
}
.edt-list .flex-one {
  margin-bottom: 20px;
}
/*----add edit-module----*/
.rght-frm .dftlp button {
  padding: 10px 15px;
  border-radius: 7px;
  border: 1px solid #00649e;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  color: #ffffff;
  background: #00649e;
  cursor: pointer;
  width: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*------client-list-table---*/
.clnt-lst .mddl button {
  background: #dfedf2;
  border: unset;
  padding: 5px 15px;
  border-radius: 6px;
  color: #34a8d9;
  font-size: 16px;
  font-weight: 500;
  line-height: unset;
  width: 100px;
}
.clnt-lst button .fa {
  margin-right: 5px;
}
.clnt-lst th .mat-checkbox {
  margin-right: 10px;
}
.clnt-lst td .mat-checkbox {
  margin-right: 10px;
}
.clnt-lst .flex-one {
  margin-bottom: 20px;
}
/*Css when clicked on any module*/
.clck_frnt {
  background: #00649e;
  color: #fff !important;
}

/*-----swati css--start------*/
.mt-innr .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0em 0 0.75em 0;
  border-top: 0px;
}
.mt-innr
  .mat-form-field-type-mat-select:not(.mat-form-field-disabled)
  .mat-form-field-flex {
  padding: 0px;
}
.mt-innr .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: translateY(-2%);
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: unset !important;
}
.mt-innr .mat-form-field-infix {
  padding: 0px !important;
  border-top: unset !important;
}
.fltr .mat-form-field {

  height: 23px;
}
.fltr .mat-date-range-input {
  font-size: 9px;
}

.mat-form-field-infix {
  border-top: none!important;
}
mat-form-field {
  width: 100%;
}
.flx-md {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.375rem 0.75rem;
  background: #c3c3c32e !important;
  border: 1px solid #f5f5f5 !important;
  border-radius: 14px !important;
}

.green-bg,
button.green-bg {
  background: #d8ffe0 !important;
  color: #38e25d;
  font-size: 13px;
  font-weight: bold;
  line-height: 22px;
  padding: 10px 20px;
  border-radius: 7px;
  width: 120px;
  border: none;
}
.srch_cn {
  position: relative;
  margin-right: 8px;
  width: 200px;
  input {
    width: 100%;
    padding: 10px 30px !important;
  }
  i.fa.fa-search {
    position: absolute;
    top: 10px;
    left: 7px;
  }
}
.btn_end button.red_bg {
  background: #ff000026;
  color: #f00505c4;
  border: none;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  padding: 10px 20px;
  border-radius: 7px;
  width: 150px;
}
.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: unset !important;
}
.flx-lft :nth-child(2) {
  margin-left: 15px;
}
ul.ngx-pagination.ng-star-inserted {
  text-align: right;
}

.invalid-feedback1{
  color: #ff0000a8;
}

.otr_srch {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.fltr svg.mat-datepicker-toggle-default-icon.ng-star-inserted {
  width: 14px!important;
  height: 14px!important;
}
 .fltr button.mat-focus-indicator.mat-icon-button.mat-button-base {
  height: 21px!important;
  width: 20px!important;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.navbar-nav.ml-auto {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
i.fa.fa-ellipsis-h {
  transform: rotate(90deg);
}
.dropdown-toggle::after {
  display: none;
}
.elipises .dropdown-menu{
 min-width: 80px!important;
 padding: 3px!important;
}
input#phone {
  padding-top: 5px;
  padding-bottom: 5px;
}
/*------swati css end---*/


.addClass{
      position: relative;
      height: 400px;
      width: 300px;
      margin: 93px auto;
}




/*----------------------------responsive------------------------------*/

/*-------------------dashboard------------------------*/

@media (max-width: 992px) {
  .modules_wrpper .flex_wrp .innr_module {
    width: 48%;
  }

  .innr-flx {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 48%;
    position: relative;
    margin-bottom: 0px;
    padding: 10px;
    border-radius: 7px;
    margin-right: 0px;
  }
    .flex-two {
      flex-wrap: wrap;
      gap: 20px;
  }
  .offr_outlet .offr {

    width: 100px;
    height: 29px;
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 100px;
    position: absolute;
    bottom: 17px;
    right: 0;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 500;
}
.flx-lft {
  width: 50%;
}
.todo-btn {
  width: 30% !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.innr-flx h3 {
  font-size: 20px;
}
.eys {
  position: absolute;
  color: #00649e;
  top: 24px;
  right: 75px;
}
.innr-flx p .s-blk {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}
.otr_srch {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}
}

@media (max-width: 600px) {
  .table-bordered th {
    font-size: 12px;
  }

  .extension_wrp .innr_module {
    width: 48%;
}
.extension_wrp .flex_wrp {
  gap: 17px;
}
.extension_wrp .flex-one h2 {
  font-size: 16px;
}
.extension_wrp .flex-one p {
  color: #00649e;
  font-size: 20px;
}
.extension_wrp .innr_module h4 {
  color: #000;
  font-size: 16px;
}


  .dashboard .flex-one .flx-rght .innr-one {
    width: 48%;
  }
  .dashboard .flex-one .prjct-stcs {
    width: 100%;
  }
  .dashboard .flex-one .prjct-rt {
    width: 100%;
  }
  .dashboard .flex-one .prjct-stcs h2 {
    font-size: 18px;
  }
  .drpdwn .mat-form-field-wrapper {
     padding-bottom: 0px !Important;
}
.dashboard .flex-one .flx-rght .flex-one {
  flex-wrap: wrap;
  gap: 15px;
}
.flx-lft h2 {
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  padding: 10px 0px;
}
.dashboard .flex-one .flx-rght .innr-one h2 {
  font-size: 18px;}
  .dashboard .flex-one .flx-rght .innr-one h2 span {
    display: block;
    font-size: 16px;
  }
  .innr-flx {
    display: flex;
    width: 100%;
  }
  .innr-flx p {
    font-size: 16px;}
    .innr-flx h4 span {
      font-size: 16px;
    }
}

@media (max-width: 480px) {
  .swal2-title {
    font-size: 16px;
  }
  .modules_wrpper .flex_wrp .innr_module {
    width: 100%;
  }
  .extension_wrp .innr_module {
    width: 100%;
  }
  .navbar-brand figure {
    width: 110px;
}
.modules_wrpper .flx-rght {
  width: 47%;
}

  .dashboard .flex-one .flx-rght .innr-one {
    width: 100%;
  }

  .eys {
    position: absolute;
    color: #00649e;
    top: 45px;
    right: 25px;
}

}
/*-------------------sub-plan-----------------------*/





